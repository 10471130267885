// modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { B2RedirectComponent } from './redirect/b2-redirect.component';
import { SignIncallbackComponent } from './sign-incallback/sign-incallback.component';

@NgModule({
    imports: [CommonModule, RouterModule, UIModule],
    declarations: [
        B2RedirectComponent,
        SignIncallbackComponent
    ],
    exports: [ B2RedirectComponent]
})
export class UtilPagesModule {}
