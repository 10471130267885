// Modules
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { SignalRHubService } from '@core/services/api/signalr/signalr-hub.service';
import { TestSignalRHubService } from '@core/services/api/signalr/test-signalr-hub.service';
import { CookieService } from '@core/services/internal/cookie.service';
import { UtilPagesModule } from '@modules/util-pages/util-pages.module';
import { GlobalErrorHandler } from '@plugins/global-error-handler.service';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { AppViewComponent } from './app.view.component';
import { AppService } from './core/services/internal/app.service';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { NewRelicPlugin } from '@plugins/new-relic.plugin';
import { AppConfig } from '@config/app.config';
import { provideAuth0, AuthService } from '@auth0/auth0-angular';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [BrowserAnimationsModule, CommonModule, CoreModule, SharedModule, UtilPagesModule, APP_ROUTES, HttpClientModule],
    declarations: [AppComponent, AppViewComponent],
    providers: [
        AppService,
        AuthService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: SignalRHubService,
            useClass: CookieService.get('IN_TEST') ? TestSignalRHubService : SignalRHubService
        },
        provideSentinelService(
            withNewRelic({
                enabled: AppConfig.config.STAGE !== 'local',
                ...NewRelicPlugin.init(AppConfig.config.STAGE === 'production', [
                    AppConfig.config.IDP_URL,
                    AppConfig.config.LIST_SERVICE_URL,
                    AppConfig.config.STUDIO_URL
                ])
            })
        ),
        provideAuth0({
          domain: AppConfig.config.LOGIN_URL,
          clientId: AppConfig.config.CLIENT_ID,
          authorizationParams: {
              redirect_uri: window.location.origin,
              audience: 'https://bannerflow.com/resources/',
              scope: 'openid bannerflow publishservice campaignservice studio analyticsbackend socialcampaignservice socialaccountservice profileservice listservice offline_access',
          },
          httpInterceptor: {
              allowedList: [
                `${AppConfig.config.APP_URL}*`,
                `${AppConfig.config.API_GATEWAY_URL}*`,
                `${AppConfig.config.SOCIAL_ACCOUNT_SERVICE_URL}*`,
                `${AppConfig.config.B2_URL}*`,

              ],
          },
          useRefreshTokens: true
      }),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
