import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IBFError,
    IBFHttpError,
    INotificationConfig,
    IUIErrorDialogConfig,
    UIErrorDialogService,
    UINotificationService
} from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { CacheService } from '@core/services/internal/cache.service';
import { SessionService } from '@core/services/internal/session.service';
import { User } from '@shared/models/user.model';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

type AllowedMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'FETCH';

/**
 * Draft on a generic error handling... (Maybe better as an interface?)
 */
export class BFError implements IBFError {
    public title: string;
    public message: string;
    public code: number;

    constructor(params: Partial<BFError> = {}) {
        Object.assign(this, params);
    }
}

/**
 * Error returned by API service when
 * an HTTP request was unsuccessful (status not equal 200)
 */
export class BFHttpError extends BFError implements IBFHttpError {
    public requestId: string;
    public requestUrl: string;
    public status: number;
    public originalResponse: any;

    public get logglyUrl(): string {
        if (!this.requestId) {
            return '';
        }
        const fromDate: Date = new Date(Date.now() - 1000 * 60 * 5);
        const encodedDate: string = encodeURIComponent(fromDate.toISOString());

        return `https://bannerflow.loggly.com/search#terms=${this.requestId}&from=${encodedDate}`;
    }

    /**
     * Creates a new instance of the BFHttpError class.
     * @param message The error message returned by the server.
     * @param status The HTTP status code.
     */
    constructor(response?: Response) {
        super();

        // Pass original response (for old errors)
        this.originalResponse = response;

        if (response) {
            let json: any;

            // If not json prevent crash
            if (response.headers.get('content-type') === 'application/json') {
                json = response.json();
            }

            if (json) {
                this.message = json.message;
            } else if (this.originalResponse.error && this.originalResponse.error.message) {
                this.message = this.originalResponse.error.message;
            }
            this.title = response.statusText;
            this.status = response.status;
            this.requestUrl = response.url;

            if (response.headers) {
                this.requestId = response.headers.get('bannerflow-request-id');
            }
        }
    }
}

/**
 * Service for making HTTP requests.
 * Also handles errors for these requests in a unified way.
 */
export interface IApiOptions {
    cache?: boolean;
    /**
     * If set to true, shows a red notification sign if error occurs. 500 errors will always trigger error dialog.
     */
    errorNotification?: boolean;
    /**
     * If set to true, removes the /api/v2/account/brand prefix in url
     */
    anonymous?: boolean;

    /**
     * If set, you need to send external service header
     */
    headers?: HttpHeaders;

    /**
     * Object with parameters that should be added on the url { value: 1, data: 'hi'} => 'value=1&data=hi'
     */
    queryParameters?: any;

    /**
     * If set to true, act on the value in the location header in the response.
     * This will make a subsequent call, using that value as the URL.
     */
    isExpectingLocationHeader?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
    constructor(
        private readonly http: HttpClient,
        private readonly cacheService: CacheService,
        private readonly sessionService: SessionService,
        private readonly errorDialogService: UIErrorDialogService,
        private readonly notificationService: UINotificationService,
        private readonly authService: AuthService,
    ) {}

    public get<T>(url: string, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'GET', null, options);
    }

    public fetch<T>(url: string, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'FETCH', null, options);
    }

    public post<T>(url: string, data: any, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'POST', data, options);
    }

    public put<T>(url: string, data: any, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'PUT', data, options);
    }

    public patch<T>(url: string, data: any, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'PATCH', data, options);
    }

    public delete<T>(url: string, options?: IApiOptions): Promise<any | T | BFHttpError> {
        return this.makeRequest(url, 'DELETE', null, options);
    }

    /**
     * Convert query parameters to url encoded string. { color: 'green', size: 46 } => 'color=green&size=46'
     * @param params
     */
    public toQueryString(params: any): string {
        let queryParams: HttpParams = new HttpParams();
        for (const i in params) {
            if (Array.isArray(params[i])) {
                params[i].forEach((param: any) => {
                    queryParams = queryParams.append(String(i), String(param));
                });
            } else {
                queryParams = queryParams.append(String(i), String(params[i]));
            }
        }

        return queryParams.toString();
    }

    protected async setupHeaders(): Promise<HttpHeaders> {
      const token: string = await this.authService.getAccessTokenSilently().toPromise();
      const headers: HttpHeaders = new HttpHeaders({
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
      });

      return headers;
    }

    private async makeRequest(
        url: string,
        method: AllowedMethods,
        body: any,
        options: IApiOptions = {}
    ): Promise<any | BFHttpError> {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

        if (options.headers) {
            headers = options.headers;
        }

        const requestOptions = { headers, body };
        const requestUrl: string = this.getRequestUrl(url, options);

        // If cache option is set to true fetch from cache
        if (method !== 'DELETE' && options.cache) {
            const cacheKey: string = url + JSON.stringify(body);
            const cachedResponse: any = this.cacheService.get(cacheKey);

            // If cache hit resolve promise with cached data
            if (cachedResponse) {
                return Promise.resolve<any>(cachedResponse);
            }
        }
        // use location header for social post requests
        if (options.isExpectingLocationHeader) {
            return firstValueFrom(this.http.request(method, requestUrl, { body, headers, observe: 'response' }))
                .then((httpRes: HttpResponse<any>) => {
                    return firstValueFrom(
                        this.http.request(
                            'GET',
                            this.getRequestUrl(httpRes.headers.get('location'), options),
                            requestOptions
                        )
                    )
                        .then((res: Response) => {
                            return this.extractData(res, false, null);
                        })
                        .catch((response: Response) => {
                            return this.handleError(response, options.errorNotification);
                        });
                })
                .catch((response: Response) => {
                    return this.handleError(response, options.errorNotification);
                });
        }
        return this.http
            .request(method, requestUrl, requestOptions)
            .toPromise()
            .then((res: Response) => {
                return this.extractData(res, false, null);
            })
            .catch((response: Response) => {
                return this.handleError(response, options.errorNotification);
            });
    }

    private getRequestUrl(url: string, options: IApiOptions = {}): string {
        let requestUrl = '';
        const user: User = this.sessionService.user;

        // Replace all macros found with user data
        if (user) {
            const macros: any = {
                accountId: user.account.id,
                accountSlug: user.account.slug,
                brandSlug: user.brand.slug,
                brandId: user.brand.id,
                userId: user.id
            };

            for (const key in macros) {
                url = url.replace(new RegExp(`\\[${key}\\]`, 'ig'), macros[key]);
            }
        }

        // Check if string begins with http://, https:// or //
        const absolute: boolean = /^(https?:)?(\/\/)+/g.test(url);

        // Temporary ugly hacks - Can
        if (absolute) {
            requestUrl = url;
        } else if (options.anonymous) {
            requestUrl = `${AppConfig.config.B2_URL}${url}`;
        } else {
            // Support urls which doesn't start with '/'
            if (url.indexOf('/') !== 0) {
                url = `/${url}`;
            }
            requestUrl = `${AppConfig.config.B2_URL}/api/v2/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}${url}`;
        }

        if (options.queryParameters) {
            requestUrl =
                requestUrl + (requestUrl.indexOf('?') === -1 ? '?' : '&') + this.toQueryString(options.queryParameters);
        }

        return requestUrl;
    }

    private handleError<T>(
        error?: Response,
        showNotificationOnHandledError?: boolean,
        jsonError?: boolean
    ): Promise<void> {
        const bfHttpError: BFHttpError = new BFHttpError(error);

        if (jsonError) {
            bfHttpError.message = 'Could not parse json string';
        }

        // Show dialog for unhandled errors
        if (bfHttpError.status === 500 || jsonError) {
            const config: IUIErrorDialogConfig = {};
            this.errorDialogService.show(config, bfHttpError);
        } else if (showNotificationOnHandledError) {
            let msg: string = bfHttpError.message ? bfHttpError.message : 'Something went wrong!';

            if (bfHttpError.status === 409) {
                msg = 'Conflict with other item, please try again';
            }

            const config: INotificationConfig = {
                type: 'error',
                autoCloseDelay: 5000,
                placement: 'top'
            };

            this.notificationService.open(msg, config);
        }

        return Promise.reject(bfHttpError);
    }

    private extractData<T>(res: Object, cache: boolean, cacheKey: string): any {
        // If cache, add to cache
        if (cache) {
            this.cacheService.add(cacheKey, res);
        }

        return res;
    }
}
