import { Location } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import {
    ActivatedRoute,
    GuardsCheckStart,
    NavigationEnd,
    NavigationExtras,
    NavigationStart,
    Params,
    Router
} from '@angular/router';
import { AnalyticsApiService } from '@modules/analytics/services/analyticsApi.service';
import { AppConfig } from '@config/app.config';
import { BannerSetService } from '@core/services/api/bannerflow/bannerset.service';
import { LocalizationService } from '@core/services/api/bannerflow/localization.service';
import { ScheduleService } from '@core/services/api/bannerflow/schedule.service';
import { CampaignApiService } from '@core/services/api/campaign/campaign-api.service';
import { ObjectType } from '@shared/enums/objectType.enum';
import { View } from '@shared/enums/view.enum';
import { Brand } from '@shared/models/brand.model';
import { AnalyticsAd } from '@shared/models/campaign/models/ad.model';
import { Localization } from '@shared/models/version/localization.model';
import { firstValueFrom } from 'rxjs';
import { AppService } from './app.service';
import { CookieService } from './cookie.service';
import { HistoryService } from './history.service';
import { SessionService } from './session.service';
import { LocalSettingsService } from './settings.service';

export class ObjectWithType {
    constructor(
        public object: any,
        public objectType: ObjectType
    ) {}

    public static stringFromObjectType(objectType: ObjectType): string {
        switch (objectType) {
            case ObjectType.BannerSet:
                return 'bannerset';
            case ObjectType.StudioCreativeSet:
                return 'creativeset';
            case ObjectType.StudioCampaign:
                return 'campaign';
            case ObjectType.StudioAd:
                return 'ad';
            case ObjectType.Schedule:
                return 'schedule';
            case ObjectType.Account:
                return 'account';
            case ObjectType.LandingPage:
                return 'landingpage';
            case ObjectType.Brand:
                return 'brand';
            default:
                return null;
        }
    }

    public static objectTypeFromString(objectType: string): ObjectType {
        switch (objectType) {
            case 'bannerset':
                return ObjectType.BannerSet;
            case 'creativeset':
                return ObjectType.StudioCreativeSet;
            case 'campaign':
                return ObjectType.StudioCampaign;
            case 'ad':
                return ObjectType.StudioAd;
            case 'schedule':
                return ObjectType.Schedule;
            case 'account':
                return ObjectType.Account;
            default:
                return null;
        }
    }
}

// Class to know what the parent of a route is
export class BackNavigation {
    constructor(
        public label: string,
        public path: any[]
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class NavigatorService {
    private navigatorPromise: Promise<any> = new Promise<void>(r => {
        r();
    });
    private objectPromise: Promise<any>;

    public navigationChange: EventEmitter<any> = new EventEmitter<any>();
    public navigationStart: EventEmitter<any> = new EventEmitter<any>();

    public rootRoute: ActivatedRoute;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService,
        private location: Location,
        private bannerSetService: BannerSetService,
        private scheduleService: ScheduleService,
        private campaignApiService: CampaignApiService,
        private historyService: HistoryService,
        private appService: AppService,
        private analyticsApiService: AnalyticsApiService,
        private localizationService: LocalizationService
    ) {
        this.router.events.subscribe((event: any) => {
            if (event instanceof GuardsCheckStart) {
                // If there is guard before navigating, remove the loading screen.
                this.navigationChange.emit('guardCheckStart');
            } else if (event instanceof NavigationEnd) {
                // Navigation has been completed
                this.navigationChange.emit('navigatorEnd');

                window.scrollTo(0, 0); // Always move to top of page when navigating

                this.handleRouteData();
                this.handleWelcomePanel();
            } else if (event instanceof NavigationStart) {
                // Navigation has been started
                this.objectPromise = null;
                this.navigationStart.emit('navigationStart');
                this.appService.toggleWelcomePanel(false);
            }
        });
    }

    public getUrlArray(relative?: boolean): string[] {
        let currentRoute: any = this.activatedRoute.root;
        let url = '';
        let urlArray: string[] = [];

        do {
            const childrenRoutes: any = currentRoute.children;
            currentRoute = null;
            childrenRoutes.forEach((route: any) => {
                if (route.outlet === 'primary') {
                    const routeSnapshot: any = route.snapshot;
                    url += `/${routeSnapshot.url.map((segment: any) => segment.path).join('/')}`;
                    urlArray = urlArray.concat(routeSnapshot.url.map((segment: any) => segment.path));
                    currentRoute = route;
                }
            });
        } while (currentRoute);

        // Make url absolute
        if (!relative && urlArray.length) {
            urlArray[0] = `/${urlArray[0]}`;
        }

        return urlArray;
    }

    /**
     * Navigate to a route. NavigatorService.navigate may be prefered to use in most cases.
     */
    public go(
        commands: any[] = [],
        relativeRoute: ActivatedRoute = null,
        preserveQueryStrings: boolean = true,
        queryParams: any = null
    ): Promise<any> {
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: preserveQueryStrings ? 'preserve' : '',
            queryParams,
            relativeTo: relativeRoute ? relativeRoute : this.rootRoute
        };

        this.navigatorPromise = this.router.navigate(commands, navigationExtras);

        return this.navigatorPromise;
    }

    /**
     * Go to latest home view
     */
    public goHome(): void {
        let url = '';

        switch (this.sessionService.user.defaultView) {
            case View.Schedules:
                url = 'Schedules';
                break;
            case View.LandingPages:
                url = 'LandingPages';
                break;
            case View.Feeds:
                url = 'Feeds';
                break;
            case View.Analytics:
                this.go(['analytics']);
                return;
            case View.CampaignManager:
                this.go(['campaigns']);
                return;
            default:
                this.go(['creative-sets']);
                return;
        }

        window.open(
            `${AppConfig.config.B2_URL}/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/${url}`,
            '_self'
        );
    }

    /**
     * Get a certain route parameter (Ex: ":brandId")
     * @param paramName
     */
    public getParam(paramName: string): any {
        return this.getParams()[paramName];
    }

    /**
     * Get all route parameters
     */
    public getParams(): Params {
        // TODO: This is a non standard way of getting route params because
        // the ActivatedRoute in this context have no params populated.

        let result: Params = Object.assign({}, this.activatedRoute.snapshot.params);
        let tmp: ActivatedRoute = this.activatedRoute.firstChild;
        while (tmp) {
            if (tmp.snapshot && tmp.snapshot.params) {
                result = Object.assign(result, tmp.snapshot.params);
            }
            tmp = tmp.firstChild;
        }

        tmp = this.activatedRoute.parent;
        while (tmp) {
            if (tmp.snapshot && tmp.snapshot.params) {
                result = Object.assign(result, tmp.snapshot.params);
            }
            tmp = tmp.parent;
        }

        return result;
    }

    /**
     * Change brand
     * @param brand
     */
    public switchBrand(brand: Brand): void {
        var route: string;

        const url = this.router.url;
        url.includes('analytics') ? route = 'analytics' : route = url.split('/').slice(-1)[0].split('?')[0];

        const origin = route === 'landingpages' ? AppConfig.config.B2_URL : window.location.origin;
        window.location.href = `${origin}/${brand.accountSlug}/${brand.slug}/${route}`;
    }

    /**
     * Set url related to an "object" (bannerset, campaign etc).
     * @param object
     * @param objectType
     */
    public setCurrentObject(object: any, objectType: ObjectType): void {
        this.objectPromise = new Promise(resolve => {
            resolve(new ObjectWithType(object, objectType));
        });
    }

    /**
     * Get object (bannerset, campaign etc) based on current url
     */
    public getCurrentObject(): Promise<ObjectWithType> {
        if (this.objectPromise) {
            return this.objectPromise;
        }

        return (this.objectPromise = new Promise<any>(async resolve => {
            await this.navigatorPromise;
            const params: any = this.getParams();
            const currObjType: ObjectType = ObjectWithType.objectTypeFromString(params.objectType);
            const hasObjIdAndType: boolean = params.objectId && params.objectType;

            if (!hasObjIdAndType) {
                return resolve(new ObjectWithType(this.sessionService.user.brand, ObjectType.Brand));
            }

            const object: Promise<any> = await this.getObject(params.objectId, currObjType);
            return resolve(new ObjectWithType(object || null, currObjType));
        }));
    }

    private async getObject(currentObjectId: string, objectType: ObjectType): Promise<any> {
        const hasDemoData: boolean = this.sessionService.hasFeature(
            SessionService.FEATURES.FEATURE_ANALYTICS_DEMO_DATA
        );
        const hasStudioAccess: boolean = this.sessionService.hasFeature(SessionService.FEATURES.STUDIO);
        const hasPermission: { [key in ObjectType]?: boolean } = {
            [ObjectType.BannerSet]: !hasDemoData,
            [ObjectType.Schedule]: !hasDemoData,
            [ObjectType.StudioCreativeSet]: !hasDemoData && hasStudioAccess,
            [ObjectType.Account]: true,
            [ObjectType.Brand]: true,
            [ObjectType.StudioCampaign]: hasStudioAccess,
            [ObjectType.StudioAd]: !hasDemoData && hasStudioAccess
        };

        if (!hasPermission[objectType]) {
            return false;
        }
        switch (objectType) {
            case ObjectType.BannerSet:
                return this.bannerSetService.getBannerSet(currentObjectId);
            case ObjectType.Schedule:
                return this.scheduleService.getSchedule(currentObjectId);
            case ObjectType.StudioCreativeSet:
                const [creativeSet] = await this.analyticsApiService.getCreativeSetsByIds([currentObjectId]);

                return creativeSet;
            case ObjectType.Account:
                return this.sessionService.user.account;
            case ObjectType.Brand:
                return this.sessionService.user.brand;
            case ObjectType.StudioCampaign:
                return this.analyticsApiService.getCampaign(currentObjectId);
            case ObjectType.StudioAd:
                const ad = await this.getAdForNavigator(currentObjectId);
                const name: string = ad.displayName || `${ad.size.width}x${ad.size.height} ${ad.language}`;
                const campaign = { id: ad.campaignId, name: ad.campaignName };

                return { ...ad, name, campaign };
            default:
                return false;
        }
    }

    /**
     * Navigate a route as a user logged in to a certain brand.
     * "/[account]/[brand]" is included in the route so just pass the rest of the route you would like to navigate)
     * @param url
     * @param preserveQueryStrings
     * @param queryParams
     */
    public navigate(url: string[], preserveQueryStrings?: boolean, queryParams?: any): Promise<boolean> {
        const route: string[] = this.getBaseRoute().concat(url);

        // Only keep query params if preserveQueryStrings is true
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: preserveQueryStrings ? 'merge' : ''
        };

        if (queryParams) {
            navigationExtras.queryParams = queryParams;
        }

        return this.router.navigate(route, navigationExtras);
    }

    /**
     * Navigate in a new tab as a user logged in to a certain brand.
     * "/[account]/[brand]" is included in the route so just pass the rest of the route you would like to navigate)
     * @param url
     * @param preserveQueryStrings
     * @param queryParams
     */
    public navigateInNewTab(url: string[]): string[] {
        const route: string[] = this.getBaseRoute().concat(url);

        window.open(route.join('/'), '_blank');

        return route;
    }

    /**
     * Set URL of current tab without triggering state change in view.
     * "/[account]/[brand]" is included in the route.
     * So just pass the rest of the route you would like to navigate
     * @param url
     */
    public setUrl(url: string[]): string[] {
        const route: string[] = this.getBaseRoute().concat(url);

        this.location.go(route.join('/'));

        return route;
    }

    private handleWelcomePanel(): void {
        if (CookieService.get('newLogin') === 'true' && !LocalSettingsService.get('dontShowWelcome')) {
            if (this.historyService.getVisitedObjects().length > 2) {
                setTimeout(() => {
                    this.appService.toggleWelcomePanel(true);
                }, 300);
            }
            CookieService.set('newLogin', 'false', 30);
        } else {
            this.appService.toggleWelcomePanel(false);
        }
    }

    private handleRouteData(): void {
        const root: any = this.router.routerState.snapshot.root;

        // Save location for cross app(a1, a2) navigation functionallity
        try {
            let child: any = root.children[0];

            while (child != null) {
                if (child.children.length === 0 && child.data) {
                    const breadcrumb: any = child.data.breadcrumb;

                    if (breadcrumb) {
                        let title: string = breadcrumb.title;
                        title = title
                            .split(' ')
                            .map((s: string) => {
                                return s.charAt(0).toUpperCase() + s.slice(1);
                            })
                            .join('');

                        this.historyService.saveLastVisitedLocation(this.router.url, title);
                    }

                    this.appService.settings.sidebar = child.data.hideSidebar ? false : true;
                    this.appService.settings.welcomePanel = child.data.hideWelcome ? false : true;
                    this.appService.settings.brandPicker = child.data.hideBrandPicker ? false : true;
                }
                child = child.children[0];
            }
        } catch (e) {}
    }

    private async getAdForNavigator(adId: string): Promise<AnalyticsAd> {
        // Getting ad
        const ad: AnalyticsAd = await this.campaignApiService.getAdForAnalytics(adId);

        const localizations: Localization[] = await firstValueFrom(this.localizationService.getLocalizations());
        ad.language = localizations.find(loc => loc.id === ad.localizationId).name;

        return ad;
    }

    /**
     * Get url from "root" including account and brand slugs as an array.
     * Sample: ['accountslug', 'brand'].
     */
    private getBaseRoute(): string[] {
        return [this.sessionService.user.account.slug, this.sessionService.user.brand.slug];
    }
}
