import { Component, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { AppService } from '@core/services/internal/app.service';
import { filter, mergeMap } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    private relevantErrors = new Set(['login_required', 'invalid_grant', 'access_denied', 'unauthorized_client']);

    constructor(
        public appService: AppService,
        public viewContainerRef: ViewContainerRef,
        private authService: AuthService
    ) {
      this.authService.error$.pipe(
        takeUntilDestroyed(),
        filter((e) => e instanceof GenericError && this.relevantErrors.has(e.error)),
        mergeMap(() => this.authService.logout())
    ).subscribe();
    }
}
